import React from 'react'

import HeaderBtn from '../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import { TableHeaderRow } from '../../../../../../Components/FinalTable'

const THead = () => {
  return (
    <div className="w-full">
    <TableHeaderRow>
      <HeaderBtn w={80 / 8} label="INV ID" />
      <HeaderBtn w={80 / 8} label=" NAME" />
      <HeaderBtn w={80 / 8} label="REQUESTED BY" />
      <HeaderBtn w={80 / 8} label="PERFORMED BY" />
      <HeaderBtn w={80 / 8} label="TO PAY" />
      <HeaderBtn w={80 / 8} label="PAYMENT METHOD" />
      <HeaderBtn w={80 / 8} label="STATUS" />
      <HeaderBtn w={80 / 8} label="DATE ADDED" />
    </TableHeaderRow>
  </div>
  )
}

export default THead