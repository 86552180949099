import React, { useEffect, useState } from "react";
import BasicModal from "../../../../../../../Components/Modals/BasicModal";

import useApp from "../../../../../../../Hooks/useApp";

import useAxios from "../../../../../../../Hooks/useAxios";

import BtnBlock from "../../../../../../../Components/Buttons/BtnBlock";

import { IoMdArrowForward } from "react-icons/io";
import usePouchCud from "../../../../../Hooks/usePouchCud";

const PerformProcedure = ({ showModal, setShowModal, doc, handleUpdate }) => {
  const request = useAxios();
  const { user, setModalMessage, setShowSuccessModal } = useApp();
  const { update } = usePouchCud();
  const [currState, setCurrState] = useState("");

  useEffect(() => {
    async function getState() {
      if (doc.id) {
        try {
          let res = await request({
            method: "GET",
            url: `hospital/visit/procedure/state?visit_procedure_id=${parseInt(
              doc.id
            )}&user_id=${user.id}`,
          });
          let mess = res.message.split(/[.]/, 2);
          setCurrState(mess);
        } catch (error) {
          //console.log(error)
        }
      }
    }
    getState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc]);

  const submit = async () => {
    let res = await request({
      method: "PUT",
      url: `hospital/visit/procedure/status`,
      data: {
        procedure_id: parseInt(doc.id),
        procedure_status: "complete",
        performed_by: parseInt(user.id),
      },
    });

    if (res !== "error") {
      setModalMessage("Procedure Performed Successfully!");
      setShowSuccessModal(true);
      await update({ name: "visit_procedure", doc: res });
      handleUpdate();
      setShowModal(false);
    }
  };

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center mx-auto">
          {currState[0]}
        </h2>
        <h2 className="font-DM-Reg text-xl text-black text-center mx-auto">
          {currState[1]}
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6 mx-auto justify-center items-center">
          <BtnBlock
            click={submit}
            mt={2.5}
            bg="primary"
            textColor="white"
            className="mx-auto"
          >
            <div className="flex text-[16px] items-center  justify-center px-2 py-1 space-x-2 ">
              Perform Procedure
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default PerformProcedure;
