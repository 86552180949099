import React, { useContext} from 'react'

import { 
  TableCell,
  TableRow,
  TableText, 
} from '../../../../../../Components/FinalTable'

import TContext from './TContext'

import SingleCheckBox from '../../../../../../Components/FinalTable/components/Actions/SingleCheckBox'

const TRow = ( {doc} ) => {

  const {checked_id, setCheckedId } = useContext(TContext)

  return (
    <TableRow key={doc.id}>
      <TableCell w={90 / 5}>
        <TableText label={doc.procedures.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.procedures.code} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.procedures.regular_price} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.procedures.description} />
      </TableCell>
      <TableCell >
      <SingleCheckBox checked_id={checked_id} setCheckedId={setCheckedId} id={doc.id} />
      </TableCell>
  </TableRow>
  )
}

export default TRow