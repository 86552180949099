import BasicModal from "../../../../../../Components/Modals/BasicModal";
import React from "react";

const ViewVitals = ({ patientVitals, showModal, setShowModal }) => {
  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <p className="flex w-full justify-center text-2xl font-DM-Bold text-gray-700">
        Visit Vitals
      </p>
      <div
        style={{ width: "60em" }}
        className=" flex items-center justify-center pb-7"
      >
        <div className="w-full ml-5 mt-8 mr-5 bg-white rounded-lg shadow-md ">
          <div className="mb-5 pr-6">
            <div className="flex flex-col p-4 space-y-8">
              <div className="grid grid-cols-3">
                <div>
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Height
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {patientVitals ? patientVitals.height : "---"}
                  </p>
                </div>
                <div className="">
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Weight
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {patientVitals ? patientVitals.weight : "---"}
                  </p>
                </div>
                <div className="">
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Temparature
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {patientVitals ? patientVitals.temparature : "---"}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-3">
                <div className="">
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Blood Pressure
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {patientVitals ? patientVitals.blood_preasure : "---"}
                  </p>
                </div>
                <div>
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Respiratory Rate
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {patientVitals ? patientVitals.respiratory_rate : "---"}
                  </p>
                </div>
                <div>
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Blood Sugar
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {patientVitals ? patientVitals.blood_sugar : "---"}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-3">
                <div className="">
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Diastolic Blood Pressure
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {patientVitals ? patientVitals.diastolic_bp : "---"}
                  </p>
                </div>
                <div>
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Systolic Blood Pressure
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {patientVitals ? patientVitals.systolic_bp : "---"}
                  </p>
                </div>
                <div>
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Comments
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {patientVitals ? patientVitals.comments : "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default ViewVitals;
