import React, { useContext, useEffect, useRef, useState } from "react";
import BasicModal from "../../../../../../../Components/Modals/BasicModal";
import TextInput from "../../../../../../../Components/Inputs/TextInput";
import TextArea from "../../../../../../../Components/Inputs/TextBox";
import useApp from "../../../../../../../Hooks/useApp";
import useAxios from "../../../../../../../Hooks/useAxios";
import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";
import BtnBlock from "../../../../../../../Components/Buttons/BtnBlock";
import { IoMdArrowForward } from "react-icons/io";
import usePouchCud from "../../../../../Hooks/usePouchCud";
import { useParams } from "react-router-dom";
import TableContext from "../TContext";
import { BsFillPlusCircleFill } from "react-icons/bs";

const VisitDocuments = ({
  showModal,
  setShowModal,
  doc,
  data,
  thisUser,
  handleUpdate,
}) => {
  const [showError, setShowError] = useState(0);
  const [files, setFiles] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [image, setImage] = useState(null);
  const input = useRef({ form: {}, err: {} });

  const request = useAxios();

  const formErrors = useHandleFormError();

  const { vpic, setvPic } = useContext(TableContext);

  const { setModalMessage, setShowSuccessModal } = useApp();

  const { update } = usePouchCud();

  const docc = useRef();

  function handleAdd() {
    docc.current.click();
    setSubmited(true);
  }

  useEffect(() => {
    if (files === null || submited === false) return;

    async function addDocument() {
      setImage(URL.createObjectURL(files[0]));
      setvPic(files[0]);
    }
    addDocument();
  }, [submited, files]);

  useEffect(() => {
    if (vpic === null) return;
    setImage(URL.createObjectURL(vpic));
  }, []);

  async function submit() {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let obj = {
      visit_procedure_id: parseInt(doc.id),
      // created_by: Number(thisUser),
      name: input.current.form.name,
      description: input.current.form.description,
      document: vpic,
    };

    let res = await request({
      method: "POST",
      url: "hospital/visit/documents/upload",
      headers: { "Content-Type": "multipart/form-data" },
      data: obj,
    });

    if (res === "error") return;
    setModalMessage("Documents Added! ");
    setShowSuccessModal(true);
    await update({ name: "visit_documents", doc: res });
    setShowModal(false);
    handleUpdate();
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Add Documents
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <TextInput
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: false,
              label: "Name",
              placeholder: "Check for any other issue",
              type: "name",
              maxChar: 250,
              minChar: 2,
            }}
          />
          <TextArea
            input={input}
            showError={showError}
            inputKey="description"
            mt={0.5}
            config={{
              required: false,
              label: "Description",
              placeholder: "",
              type: "text",
              inputType: "text",
              maxChar: 1000,
              minChar: 4,
            }}
          />
          <div className="w-full h-full flex justify-center items-center">
            <div className=" bg-bright_grey p-8 rounded-2xl relative">
              <img
                src={image ? image : ""}
                alt="user"
                style={{ maxWidth: "350px", maxHeight: "350px" }}
              />
              <span
                onClick={handleAdd}
                className=" text-5xl cursor-pointer absolute right-3 bottom-2 shadow-2xl bg-transparent rounded-full z-50 text-primary"
              >
                <BsFillPlusCircleFill />
              </span>
            </div>
            <input
              className=" hidden"
              ref={docc}
              type="file"
              id="myFile"
              name="filename"
              accept="image/png, image/jpeg, application/pdf"
              onChange={(e) => setFiles(e.target.files)}
            />
          </div>
          <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white">
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Add Documents</span>
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default VisitDocuments;
