import React, { useState, useEffect } from "react";

import { colors } from "./../../Util/colors";

const CheckBox = ({
  input,
  inputKey,
  initial = false,
  label = "Text input",
  mt = 0,
  mb = 0,
}) => {
  const [checkInput, setCheckInput] = useState(initial);
  const [borderColor, setBorderColor] = useState("bright_grey");

  function handleChange(txt) {
    setCheckInput(txt);
    input.current.form[inputKey] = txt;
  }

  function handleBlur() {
    input.current.form[inputKey] = checkInput;
  }

  return (
    <div
      className="flex flex-col"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <div className="flex items-center gap-3">
        <input
          className={`border-2 rounded-lg h-5 w-5 cursor-pointer checked:span>text-primary placeholder-cadet_grey `}
          onFocus={() => setBorderColor("primary")}
          style={{
            borderColor: colors[borderColor],
          }}
          type="checkbox"
          checked={checkInput}
          onChange={(e) => handleChange(!checkInput)}
          onBlur={handleBlur}
        />
        <label className=" font-DM-Reg text-base font-medium">{label}</label>
      </div>
    </div>
  );
};

export default CheckBox;
