import Tooltip from "../../../Components/ToolTip";
import BtnRounded from "../../../../../Components/Buttons/BtnRounded";
import { AiFillEye } from "react-icons/ai";

import { ImLab } from "react-icons/im";

import { useNavigate } from "react-router-dom";

const InvestigationTip = ({
  open = false,
  active = false,
  setShowPatient,
  visitStep = null,
}) => {
  const id = "inv-tooltip";
  const navigate = useNavigate();

  function handleAttend() {
    if (visitStep === null) return;
    navigate(`/dashboard/attend/${visitStep}`);
  }

  return (
    <div data-tip data-for={id} className="w-full" onClick={handleAttend}>
      <BtnRounded click={() => setShowPatient(true)}>
        <ImLab />
      </BtnRounded>
      <Tooltip id={id} txt1="Add Investigations" txt2="Add Procedures">
        <ImLab />
      </Tooltip>
    </div>
  );
};

export default InvestigationTip;
