import React from 'react'

import HeaderBtn from '../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  } from '../../../../../../Components/FinalTable'

const THeader = () => {
  return (
    <TableHeaderRow>
      <HeaderBtn w={90 / 5} label=" NAME"  />
      <HeaderBtn w={90 / 5} label="CODE"  />
      <HeaderBtn w={90 / 5} label="REGULAR PRICE" />
      <HeaderBtn w={90 / 5} label="DESCRIPTION" />
      
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default THeader