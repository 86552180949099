import React, { useContext, useState } from 'react'
import BtnPlain from '../../../../../../../Components/Buttons/BtnPlain'
import useAxios from '../../../../../../../Hooks/useAxios';
import useApp from '../../../../../../../Hooks/useApp';

import usePouchCud from '../../../../../Hooks/usePouchCud';
import { FaDownload, FaRegTrashAlt } from 'react-icons/fa';
import TableContext from './TContext';
import useDownloader from 'react-use-downloader';

const TableActions = ({ doc}) => {

    const request = useAxios()
    const { setShowSuccessModal, setModalMessage } = useApp();
    const {data, setData, handleUpdate} = useContext(TableContext)
    const {deleteDoc} = usePouchCud()
    const {download} = useDownloader()
    const [updates, setUpdates] = useState(false)

    

    async function handleDownload(doc) {
        let res = await request({
          method: "POST",
          url: "hospital/visit/documents/download",
          data: {
           url: doc.url,
          },
        });
        if (res === "error") return;
        download(res.link, doc.url);
    }

    const handleDelete = async () => {
        let res = await request({
            method: "DELETE",
            url: `hospital/visit/documents/${doc.id}`
          });
      
          if (res !== "error") {
            
            setModalMessage("Visit document Deleted!");
            setShowSuccessModal(true);
            await deleteDoc({name: 'visit_documents', doc:res})
            handleUpdate()
            let newDocs = data.filter((row) => (row.id === doc.id ? false : true));
            setData(newDocs);
          }
    }

    return (
        <div className="flex-row">
            <BtnPlain
                activeBgColor="white"
                bg="white"
                click={() => handleDownload(doc)}
            >
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                    <p className="  text-primary">
                        <FaDownload />
                        
                    </p>
                </div>
            </BtnPlain>
            <BtnPlain
                activeBgColor="white"
                bg="white"
                click={handleDelete}
            >
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                    <p className="  text-red">
                        <FaRegTrashAlt />
                    </p>
                </div>
            </BtnPlain>
        </div>
    )
}

export default TableActions