import { useEffect, useState } from "react";

import { Table } from "../../../../../../Components/FinalTable";

import THeader from "./THeader";

import TBody from "./TBody";

import TInfo from "./TInfo";

import TContext from "./TContext";

import usePouchPagination from "../../../../../../Hooks/usePouchPagination";

import { TableTopBar } from "../../../../../../Components/FinalTable";

import useFuzzySearch from "../../../../../../Hooks/useFuzzySearch";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../../Hooks/useAxios";
import useApp from "../../../../../../Hooks/useApp";
import useFeatures from "../../../../Hooks/useFeatures";
import usePouchCud from "../../../../Hooks/usePouchCud";

const PouchData = () => {
  const [changes, setChanges] = useState(0);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [checked_id, setCheckedId] = useState(-1);
  const [showPagination, setShowPagination] = useState(true);
  const { id } = useParams();
  const { create } = usePouchCud();
  const [visitId, setVisitId] = useState(0);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const { procedures, resetChanges } = useFeatures();

  const { setShowFormModal, setModalMessage, setShowSuccessModal, user } =
    useApp();

  const [docs, setDocs] = useState(0);

  const request = useAxios();

  const navigate = useNavigate();

  const db_name = "procedures";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };
  const searchOptions = {
    keys: ["name"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed,
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
  });

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  async function handleLiveStream() {
    resetChanges("procedures");
    await resetQuery();
    //Refresh the table
  }

  useEffect(() => {
    async function Initialize() {
      try {
        let res = await request({
          method: "GET",
          url: `hospital/records/consultation?visit_step_id=${id}`,
        });
        setVisitId(res.visit.id);
      } catch (error) {
        //console.log(error)
      }
    }

    Initialize();
  }, [id, checked_id]);

  async function Submit() {
    if (docs.length === 0) {
      setModalMessage("Select at least one Fixed Asset");
      setShowFormModal(true);
      return;
    }
    let res = await request({
      method: "POST",
      url: "hospital/visit/procedure",
      data: {
        visit_id: parseInt(visitId),
        procedure_id: parseInt(checked_id),
        requested_by: parseInt(user.id),
      },
    });

    if (res !== "error") {
      setModalMessage("Procedure Added");
      setShowSuccessModal(true);
      await create({ name: "visit_procedure", doc: res });
      navigate(`/dashboard/attend/${id}`);
    }
  }

  return (
    <TContext.Provider
      value={{
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
        checked_id,
        setCheckedId,
        docs,
        setDocs,
      }}
    >
      <div className="px-7 pb-8">
        <TableTopBar
          mt={2}
          mb={2}
          showAdd={false}
          // AddClick={handleAdd}
          changes={changes}
          handleLiveStream={handleLiveStream}
          isFiltered={isFiltered}
          handleClearFilters={handleClearFilters}
          tableIsLoading={tableIsLoading}
          showReport={false}
          showFilter={false}
          // addLabel="Add"
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <Table>
          <THeader />
          <TBody />
          <TInfo />
          <div className="max-w-2xl w-full mx-auto">
            <BtnRounded mt={2} click={Submit} className="btn">
              <span className=" font-pop-reg">Add Procedure</span>
              <span className="px-3 font-normal text-2xl"></span>
            </BtnRounded>
          </div>
        </Table>
      </div>
    </TContext.Provider>
  );
};

export default PouchData;
