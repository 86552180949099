import Tooltip from "../../../../Components/ToolTip";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import {  CgComment } from "react-icons/cg";

const PatientComment = ({ open = false, active = false, setShowComment }) => {

  const id = "comments-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded
        
        click={() => setShowComment(true)}
      >
        <CgComment />
      </BtnRounded>
      <Tooltip
        id={id}
        txt1="View Visit-Step"
        txt2="Comments"
      >
        <CgComment />
      </Tooltip>
    </div>
  );
};

export default PatientComment;
