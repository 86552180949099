import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import AppContext from "./Context/AppContext";

import NotFound from "./Pages/404";
// import useAxios from "./Hooks/useAxios";

import SignIn from "./Pages/SignIn";

import Dashboard from "./Pages/Dashboard";

import Summary from "./Pages/Dashboard/Features/Summary";

import Patients from "./Pages/Dashboard/Features/Patients";

import AddPatient from "./Pages/Dashboard/Features/Patients/Add";

import AllPatients from "./Pages/Dashboard/Features/Patients/All";

import NextSteps from "./Pages/Dashboard/Features/NextStep";

import CheckInPatient from "./Pages/Dashboard/Features/Patients/CheckIn";

import EditPatient from "./Pages/Dashboard/Features/Patients/Edit";

import InsurancePatient from "./Pages/Dashboard/Features/Patients/Insurance";

import ViewPatient from "./Pages/Dashboard/Features/Patients/View";

import HospitalQueue from "./Pages/Dashboard/Features/HospitalQueue";

import ReceptionistQueue from "./Pages/Dashboard/Features/ReceptionistQueue";

import SpaceQueue from "./Pages/Dashboard/Features/SpaceQueue";

import Requests from "./Pages/Dashboard/Features/Requests";

import AllRequests from "./Pages/Dashboard/Features/Requests/All";

import AddRequest from "./Pages/Dashboard/Features/Requests/Add";

import VisitLogs from "./Pages/Dashboard/Features/VisitLogs";

import AllVisitLog from "./Pages/Dashboard/Features/VisitLogs/All";

import ViewVisitLog from "./Pages/Dashboard/Features/VisitLogs/View";

import Protected from "./Protected";

// Attend From Doctors APP

import AllAttend from "./Pages/Dashboard/Features/Attend/AllAttend";
import Attend from "./Pages/Dashboard/Features/Attend";
import InvestigationCsh from "./Pages/Dashboard/Features/Attend/AddItems/InvestigationTable";
import InvestigationIns from "./Pages/Dashboard/Features/Attend/AddItems/InvestigationInsuranceTable";
import ProcedureCsh from "./Pages/Dashboard/Features/Attend/AddItems/ProcedureTable";
import ProcedureIns from "./Pages/Dashboard/Features/Attend/AddItems/ProcedureInsuranceTable";

import ConsumableCashAdd from "./Pages/Dashboard/Features/Attend/AddPrescriptions/CashPrescriptions";
import ConsumableInsuranceAdd from "./Pages/Dashboard/Features/Attend/AddPrescriptions/InsurancePrescriptions";

// Attend From Doctors APP.

//ALL MODALS

import LoadingModal from "./Components/Modals/LoadingModal";

import RequestModal from "./Components/Modals/RequestModal";

import FormModal from "./Components/Modals/FormModal";

import SuccessModal from "./Components/Modals/SuccessModal";

const APP = () => {
  // const request = useAxios();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({});

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [modal_type, setModalType] = useState(null);
  const [modal_message, setModalMessage] = useState(null);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [signal, setSignal] = useState({});

  return (
    <AppContext.Provider
      value={{
        signal,
        setSignal,
        setIsLoading,
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        token,
        setToken,
        showRequestModal,
        setShowRequestModal,
        modal_type,
        setModalType,
        modal_message,
        setModalMessage,
        setShowFormModal,
        setShowSuccessModal,
        isOnline,
        setIsOnline,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="" element={<SignIn />} />
          <Route path="" element={<Protected />}>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="" element={<Summary />} />
              <Route path="patients" element={<Patients />}>
                <Route path="" element={<AllPatients />} />
                <Route path="add" element={<AddPatient />} />
                <Route path="edit/:id" element={<EditPatient />} />
                <Route path="view/:id" element={<ViewPatient />} />
                <Route path="check-in/:id" element={<CheckInPatient />} />
                <Route path="insurance/:id" element={<InsurancePatient />} />
              </Route>
              <Route path="hospital-queue" element={<HospitalQueue />} />
              <Route path="user-queue" element={<ReceptionistQueue />} />
              {/* <Route path="space-queue" element={<SpaceQueue />} /> */}
              <Route path="requests" element={<Requests />}>
                <Route path="" element={<AllRequests />} />
                <Route path="add" element={<AddRequest />} />
              </Route>
              <Route path="visit-logs" element={<VisitLogs />}>
                <Route path="" element={<AllVisitLog />} />
                <Route path="view/:id" element={<ViewVisitLog />} />
              </Route>
              <Route path="next-step/:visit" element={<NextSteps />} />

              {/* From doctors App */}

              <Route path="attend/*" element={<AllAttend />}>
                <Route path=":id" element={<Attend />} />
                <Route
                  path=":id/add/procedure/cash"
                  element={<ProcedureCsh />}
                />
                <Route
                  path=":id/add/procedure/Insurance"
                  element={<ProcedureIns />}
                />
                <Route
                  path=":id/add/investigation/cash"
                  element={<InvestigationCsh />}
                />
                <Route
                  path=":id/add/investigation/insurance"
                  element={<InvestigationIns />}
                />
              </Route>
            </Route>

            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
      <LoadingModal showModal={isLoading} />
      <RequestModal
        showRequestModal={showRequestModal}
        setShowRequestModal={setShowRequestModal}
        modal_type={modal_type}
        modal_message={modal_message}
      />{" "}
      <FormModal
        showFormModal={showFormModal}
        setShowFormModal={setShowFormModal}
        modal_message={modal_message}
      />
      <SuccessModal
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
        modal_message={modal_message}
      />
    </AppContext.Provider>
  );
};

export default APP;
