import React, { useEffect, useState } from "react";
import { Table } from "../../../../../../../Components/FinalTable";
import TBody from "./TBody";
import THead from "./THeader";
import { useNavigate, useParams } from "react-router-dom";
import TableContext from "./TContext";

const CommentsTable = ({ comments, handleUpdate }) => {
  const [limit, setLimit] = useState(30);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [showPagination, setShowPagination] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [fixed, setFixed] = useState({});


  useEffect(() => {
    async function initialize() {
      setData(comments);
    }
    initialize();
  }, []);

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        data,
        comments,
        handleUpdate,
        setData,
        isFiltered,
        setIsFiltered,
        showPagination,
        setFixed,
      }}
    >
      <div className="flex flex-col items-start w-full bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex flex-row justify-between w-full">
          <p className="font-DM-Med text-lg uppercase text-BLACK"></p>
          <div className=""></div>
        </div>
        <Table>
          <THead />
          <TBody />
          {/* <TInfo /> */}
        </Table>
      </div>
    </TableContext.Provider>
  );
};

export default CommentsTable;
