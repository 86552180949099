import React, { useEffect, useState } from 'react'
import { TableCell, TableText, TableRow } from '../../../../../../Components/FinalTable'
import { convDate } from '../../../../../../Util/Time'
import TableActions from './TableActions'
import Badge from '../../../../../../Components/Badges/Badge'

const TRow = ({ doc }) => {

  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={80 / 8}>
          <TableText label={doc.investigation_id || doc.scheme_investigation_id} />
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText label={` ${doc.payment_method == 'Insurance' ? doc.scheme_investigation.investigations.name : doc.payment_method == 'Cash' ? doc.investigations.name : ""}`} />
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText label={doc.team_visit_investigation_requested_byToteam ? doc.team_visit_investigation_requested_byToteam.name : ''} />
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText label={doc.team_visit_investigation_performed_byToteam ? doc.team_visit_investigation_performed_byToteam.name : ''} />
        </TableCell>
         <TableCell w={80 / 8}>
          <TableText label={doc.to_pay} />
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText label={doc.payment_method} />
        </TableCell>
        <TableCell w={80 / 8}>
        {doc.investigation_status == 'pending' ? <Badge label={doc.investigation_status} bg={"pale_spring_bud"} /> : doc.investigation_status == 'canceled' ? <Badge label={doc.investigation_status} bg={"pale_pink"} /> : doc.investigation_status == 'complete' ? <Badge label={doc.investigation_status} bg={"bright_grey"} /> : ' '}
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell>
            <TableActions doc={doc}  />
          </TableCell>
      </TableRow>
    </>
  )
}

export default TRow