import { useContext } from "react";
import FeatureContext from "../FeatureContext";

const useFeatures = () => {
  const docs = useContext(FeatureContext);

  return docs;
};

export default useFeatures;
