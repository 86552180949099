import React, { useContext } from 'react'

import TContext from './TContext';

import TRow from './TRow'



const TBody = () => {

  const { data } = useContext(TContext);

  return (
    <>
      {data.map((doc) => (
        <TRow key={doc.id || doc._id} doc={doc} />
      ))}
    </>
  )
}

export default TBody